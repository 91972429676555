.App, .page-content {
  text-align: center;
  font-family: Arial, sans-serif;
  min-height: 100vh;  /* Ensure content extends to full height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white; 
}

body {
  background-image: url('../Assets/Alienware-Wallpapers-3.jpg');
  background-size: cover;       /* Cover the entire screen */
  background-repeat: no-repeat; /* Prevent the image from tiling */
  background-position: center;  /* Center the image */
  height: 200vh;
  margin: 0;
  font-family: Arial, sans-serif; /* Adjust if you have a different font */
}





.info-tab {
  font-family: "Sour Gummy", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
color: rgb(7, 243, 35);

}

.Equal-sign {
  margin: 0 10px; 
}



.info-strong{
  font-family: "Doto", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  font-variation-settings:
    "ROND" 0;
color: red;
}

.Target-tab {
  color: red;
}

.Target-strong{
  color: rgb(0, 255, 42);
}
.applications-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
 
}


.app-box {
  cursor: pointer;
  font-family: "Sour Gummy", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
  border: none;
  padding: 15px;
  text-align: center;
  background-color: rgb(0, 255, 55);
  color: red;
  border-radius: 25px;
}

.app-box:hover {
background-color: red;
color: black;

}


button {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #555;
}

.brute-bypass{
  color: rgb(243, 7, 7);
  font-size: 20px;
  font-family: "Geist Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.form-container {
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 20px;
  color: rgb(7, 243, 35);
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Optional: Add subtle shadow to form */
  max-width: 500px; /* Optional: Control form width */
  width: 100%;
  margin: 0 auto;
  position: relative; /* Position relative to the parent container */
  z-index: 1; /* Make sure it's above any background elements */
}

label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
}

input[type="text"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  color: rgb(7, 243, 35);
}


.grid-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  gap: 35px; /* Space between form elements */
}

.export-button {
  background-color: rgba(244, 15, 15, 0.7); /* Semi-transparent black */
  color: rgb(7, 243, 35);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%; /* Make the button fill the form's width */
  font-size: 16px;
  border-radius: 5px; /* Rounded corners */
  justify-content: center;
  margin-left: 145px;
}

/* Button hover effect */
.export-button:hover {
  background-color: rgba(29, 250, 5, 0.9); /* Darken when hovered */
  color: red;
}


.import-er { 
  animation: 2s anim-popoutin ease infinite;
  font-family: "Libre Baskerville", serif;
  font-weight: 700;
  font-style: normal;
}

@keyframes anim-popoutin {
  0% {
    color: rgb(22, 230, 11);
    transform: scale(0);
    opacity: 0;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  25% {
    color: red;
    transform: scale(2);
    opacity: 1;
    text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
  }
  50% {
    color: rgb(246, 7, 7);
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(236, 5, 5, 0);
  }
}

.fromtop {
  animation: 2s anim-fromtop linear infinite;
}

@keyframes anim-fromtop {
  0% { opacity: 0; transform: translateY(-100%); }
  25% { opacity: 1; transform: translateY(0%); }
  50% { }
  100% { }
}

.App-file {
  color: rgb(7, 243, 35);
  font-family: "Sour Gummy", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.dropdown-content {
  border-top: 1px solid #ccc;
  padding-top: 10px;
  margin-top: 10px;
}

.drop-down {
  color: red;
  font-family: "Geist Mono", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.drop-strong {
  color: rgb(11, 237, 53);
  font-family: "Geist Mono", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
