/* Ensure the font is imported if necessary */
@import url('https://fonts.googleapis.com/css2?family=Doto:wght@400&display=swap');


/* Before pseudo-element for the animated line */
.Head-tab:before {
  content: "";
  position: absolute;
  top: calc(100% - 2px);
  width: 100%;
  height: 4px;
  background-color: #cf0000;
  transform-origin: center center;
  transform: scaleX(0);

}



/* Styling for the h1 element with the id "page-logo" */
h1#page-logo {
  font-family: "Doto", sans-serif !important;
  font: bold 2rem;
  animation: clip-path-reveal-1 3s ease infinite alternate;
  font-weight: 500;
  color: red;
}



/* Keyframes for the clipping effect on the text */
@keyframes clip-path-reveal-1 {
  0%, 25% { clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%); }
  50% { clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }
}
